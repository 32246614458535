import React, { useEffect } from 'react';
import { Global } from '@emotion/core';
import styled from '@emotion/styled';
import { useColorMode } from 'theme-ui';

import NavigationFooter from '../Navigation/Navigation.Footer'
import ArticlesContextProvider from '../../sections/articles/Articles.List.Context';

import { globalStyles } from '@styles';
import CookieToast from '../../../../components/toasts/CookieToast';

/**
 * <Layout /> needs to wrap every page as it provides styles, navigation,
 * and the main structure of each page. Within Layout we have the <Container />
 * which hides a lot of the mess we need to create our Desktop and Mobile experiences.
 */
const Layout: React.FC<{}> = ({ children }) => {
  const [colorMode] = useColorMode();
  const isDark = colorMode[0] === "dark";
  const fill = isDark ? "#fff" : "#000";

  useEffect(() => {
    parent.postMessage({theme: colorMode}, '*');
  }, [colorMode]);

  const Overlays = () => {
    return (
      <>
        <CookieToast fill={fill} />
      </>
    )
  }

  return (
      <ArticlesContextProvider>
        <Container>
          <Global styles={globalStyles}/>
          {children}
          <Overlays />
          <NavigationFooter/>
        </Container>
      </ArticlesContextProvider>
  );
}

export default Layout;

const Container = styled.div`
  position: relative;
  background: ${p => p.theme.colors.background};
  transition: ${p => p.theme.colorModeTransition};
  min-height: 100vh;
  overflow-x: hidden;
`;
