/**
 * @type { Copyright: { Company: string; Year: number; Author: string }; Social: ({ name: string; url: string } | { name: string; url: string } | { name: string; url: string } | { name: string; url: string } | { name: string; url: string })[]; Description: { Meta: string; Hero: string }; Title: { Default: string; Main: string; Short: string }; BaseUrl: string; Icon: { Default: string }; Tags: { Default: string[] } }
 */
const AppRegistry = {
    BaseUrl: "https://nuvahs.com/",
    Title: {
        Default: 'Designing welcoming spaces starts with the heart...',
        Short: 'Explore our premium home furnishings',
        Main: 'Nu Designs'
    },
    Description: {
        Meta: "Let us create welcoming spaces in your home with our team of award-winning interior designers.",
        Hero: 'Let us create welcoming spaces in your home with our team of award-winning interior designers.',
        Media: {
            Label: 'Watch deep house spot',
            Channel: 'youtube',
            VideoId: '8t_bRErEuWQ'
        },
        Newsletter: {
            Lead: 'Sign up for weekly mailing list with curated design ideas.',
            Complete: "Thanks! We'll be in touch.",
            Disclaimer: "By signing up you agree to our [Privacy Policy](/privacy-policy) and [Terms of Service](/terms-of-service)."
        },
        Footer: {
            Title: 'Design starts with the heart...'
        }
    },
    Copyright: {
        Company: 'Nuvahs Inc. or affiliates.',
        Author: 'Sar Malik',
        Affiliate: {
            Label: "Powered by Quant ONE",
            Link: "https://quant.one/?ref=nuvahs.com",
        },
        Year: 2021
    },
    Contact: {
        Bookings: {
            Min30: 'mailto:designers@nuvahs.com?subject=Design%20Consultation',
        },
        Email: {
            Raw: 'designers@nuvahs.com',
            Parameters: 'mailto:designers@nuvahs.com?subject=Design%20Consultation'
        },
        Twitter: {
            Company: '@nuvahsdesign',
            Author: '@nuvahsdesign'
        }
    },
    Social: [
        {
            name: `twitter`,
            url: `https://twitter.com/nuvahsdesign`,
        },
        {
            name: `instagram`,
            url: `https://instagram.com/nuvahs`,
        }
    ],
    Icon: {
        "Default": "src/assets/nu.logo.png"
    },
    Author: {
        Default: 'Sar Malik'
    },
    Tags: {
        Header: '',
        Default: [
            'Contemporary',
            'Modern',
            'Craftsmanship',
            'Refined',
            'Luxury',
            'Premium',
            'Materials',
            'Furnishings',
            'Interior',
            'Consulting',
            'Design'
        ],
        Compose: [
            'Elegant',
            'Modern',
            'Hand Made',
            'Imported',
            'Refined',
            'Designer',
            'Curated',
            'Made for you'
        ]
    },
    Tokens: {
        WebTrust: '0000000000000000000',
        SafeWeb: '000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000',
        GoogleTags: [
            'G-6RQDHYFGPZ'
        ],
        Hotjar: {
            Id: 2201027,
            Sv: 6
        },
        Mailchimp: 'https://gmail.us1.list-manage.com/subscribe/post?u=9585414aafd641d1354ef2124&amp;id=46908a8bc7'
    }
};

export default AppRegistry;
