import React from "react";
import styled from "@emotion/styled";
import {graphql, Link, StaticQuery} from "gatsby";
import {useColorMode} from "theme-ui";

import Section from "@components/Section";
import Headings from '@narative/gatsby-theme-novela/src/components/Headings';
import { Link as ExtLink } from 'rebass';
import {Flex, Box} from '@rebass/grid';
import {UserCheck} from "@styled-icons/feather";
import {MainCover} from "@digitaltransformation/qone.pages.layout";
import AppRegistry from "../../../../meta/AppRegistry";
import Logo from "../Logo/Logo";

const siteQuery = graphql`
    query {
        allSite {
            edges {
                node {
                    siteMetadata {
                        name
                    }
                }
            }
        }

        sitePlugin(name: {eq: "@narative/gatsby-theme-novela"}) {
            pluginOptions {
                rootPath
                basePath
            }
        }
    }
`;

interface IFooterProps {
    fill: boolean | string
}

class Footer extends React.Component<IFooterProps> {
    public render() {
        return (
            <StaticQuery query={siteQuery} render={(data) => {
                const {name} = data.allSite.edges[0].node.siteMetadata

                return (
                    <MainCover
                        media={{
                            url: undefined,
                            placeholder: undefined,
                            styles: {
                                transform: 'scale(1)',
                                width: '100%',
                                height: '100%',
                                objectFit: 'contain',
                                objectPosition: 'right bottom'
                            }
                        }}
                    >
                        <Section narrow
                                 style={{
                                     padding: '3em',
                                 }}
                        >
                            <FooterContainer
                                flexWrap={'wrap'}
                                style={{
                                    color: `${this.props.fill}`,
                                }}
                            >
                                <Box width={[1, 1 / 4]} p={[2]}>
                                    <Link
                                        to={data.sitePlugin.pluginOptions.basePath}
                                        data-a11y="false"
                                        title="Navigate back to the homepage"
                                        aria-label="Navigate back to the homepage"
                                    >
                                        <Logo
                                            fill={this.props.fill.toString()}
                                        />
                                    </Link>
                                </Box>

                                <Box width={[1, 1 / 2]} p={[2, 3]}>
                                    <FooterTitle>
                                        {AppRegistry.Description.Footer.Title}
                                    </FooterTitle>
                                </Box>
                            </FooterContainer>

                            <HorizontalRule/>

                            <FooterContainer
                                flexWrap={'wrap'}
                                style={{
                                    textAlign: 'left',
                                    opacity: 0.33,
                                    color: this.props.fill
                                }}
                            >
                                <Box width={[1, 1 / 3]}>
                                    <FooterText>
                                        © {name}
                                    </FooterText>
                                </Box>
                                <Box width={[1, 2/3]}>
                                    <FooterText width={1}>
                                        <Link to={AppRegistry.Contact.Email.Parameters}>
                                            {'Support'}
                                        </Link>
                                        <Link to={'privacy-policy'}>{'Privacy Policy'}</Link>
                                        <Link to={'terms-of-service'}>{'Terms of Service'}</Link>
                                        <Link to={'returns-policy'}>{'Returns Policy'}</Link>
                                        <ExtLink href={AppRegistry.Copyright.Affiliate.Link}>{AppRegistry.Copyright.Affiliate.Label}</ExtLink>
                                    </FooterText>
                                </Box>
                            </FooterContainer>
                        </Section>
                    </MainCover>
                )
            }}/>
        )
    }
}

const StateWrapper = component => props => {
    const colorMode = useColorMode();
    const isDark = colorMode[0] === 'dark';
    const fill = isDark ? "#fff" : "#000";

    return <Footer {...props} fill={fill}/>
};

export default StateWrapper(Footer);

const FooterContainer = styled(Flex)`
    text-align: center;
    align-items: center;
    margin: 3em auto;
`

const HorizontalRule = styled(Box)`
  border-bottom: 1px dashed ${p => p.theme.colors.horizontalRule};
`

const FooterText = styled(Box)`
  color: ${p => p.theme.colors.secondary};
  
  a {
    text-align: right;
    color: ${p => p.theme.colors.accent};
    padding: 0.25em 0;
  }
  
  * {
    margin: 0 1em;
    display: block;
  }
`

const FooterTitle = styled(Headings.h3)`
  vertical-align: bottom;
  color: dimgray;
  display: flex;
  text-align: left;
`