import * as React from "react";
import { Text, Link, Image } from "rebass";
import { Flex, Box } from "@rebass/grid";
import { ToastOptions, toast } from "react-toastify";
import ToastContainerS from "./ToastContainerS";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import { Check } from "@styled-icons/feather";

const _c = {
    image: "",
    text:
        "We care about your experience and would love to use cookies to make it better.",
    action: {
        label: "Privacy Policy",
        route: "/privacy-policy",
    },
};

interface IToastProps {
    onClick?: () => any;
    onClose?: () => any;
    fill?: string;
    image?: URL | string;
    text?: string;
    action?: {
        label: string;
        route: URL | string;
    };
}

class CookieToast extends React.Component<IToastProps> {
    private static ToastProps: ToastOptions = {
        toastId: "id-cookie-toast",
        delay: 500,
        autoClose: 5000,
        draggable: true,
        position: "bottom-center",
        hideProgressBar: false,
        closeOnClick: true,
        onClick: () =>
            console.log("[CookiePipeline]: Explicit consent granted"),
        onClose: () =>
            console.log("[CookiePipeline]: Cookie trigger registered"),
    };

    private static ToastContent(props) {
        return (
            <>
                <Flex flexWrap={"wrap"}>
                    <Box width={[1, 1 / 10]}>
                        {props.image ? (
                            <Image src={props.image} />
                        ) : (
                            <Check width={24} strokeWidth={2} />
                        )}
                    </Box>
                    <Box width={[1, 9 / 10]}>
                        <Text pb={[3, 5]} pr={[3, 5]}>
                            {props.text ?? _c.text}
                        </Text>
                        <Link href={props.action?.route ?? _c.action.route}>
                            {props.action?.label ?? _c.action.label}
                        </Link>
                    </Box>
                </Flex>
            </>
        );
    }

    public render() {
        const ToastContainerFill = styled(ToastContainerS)`
            & .toasts {
                background: ${this.props.fill == "#fff" ? "#030303" : "#fff"};
                border: ${this.props.fill == "#fff"
                    ? "1px solid #0d0d0d"
                    : "initial"};

                & .Toastify__close-button--default {
                    color: ${this.props.fill};
                }
            }
        `;

        return (
            <ToastContainerFill>
                {toast(
                    <CookieToast.ToastContent props={this.props} />,
                    CookieToast.ToastProps
                )}
            </ToastContainerFill>
        );
    }
}

export default CookieToast;
